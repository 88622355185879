import React from 'react'
import { graphql } from 'gatsby'
import './ContactPage.scss'
import Layout from '../components/Layout';
import PageTemplate from '../components/contact/PageTemplate'


const ContactPage = ({ data: { page } }) => {
  return (
        <Layout
          meta={page.frontmatter.meta || false}
          title={page.frontmatter.title || false}
        >
          <PageTemplate data={page}/>
        </Layout>
)}

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        offices {
          image
          address
          contactNumber
          city
        }
        description
      }
    }
  }
`
